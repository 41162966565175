<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Edit Eksekutor'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="pabrik_id_label"
                  label="Pabrik :"
                  label-for="pabrik_id"
                >
                  <b-form-select
                    id="pabrik_id"
                    v-model="$v.form.pabrik_id.$model"
                    :options="pabrik"
                    :state="validateState('pabrik_id')"
                  ></b-form-select>

                  <b-form-invalid-feedback id="nama_pabrik_feedback">
                    Harap pilih salah satu pabrik
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_eksekutor_label"
                  label="Nama Eksekutor :"
                  label-for="nama_eksekutor"
                >
                  <b-form-input
                    id="nama_eksekutor"
                    v-model="$v.form.nama_eksekutor.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_eksekutor')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_eksekutor_feedback">
                    Nama Eksekutor Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/master/eksekutor')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  name: 'addeksekutor',
  data() {
    return {
      form: {
        nama_eksekutor: null,
        pabrik_id: null,
      },
      pabrik: [],

      loading: false,
    }
  },
  validations: {
    form: {
      nama_eksekutor: {
        required,
      },
      pabrik_id: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Eksekutor', route: '/master/eksekutor' },
      { title: 'Edit Master Eksekutor' },
    ])
  },
  async created() {
    var self = this

    self.loading = true

    await ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          // self.pabrik = pabrik;
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    await ApiService.get('/master/eksekutor/edit/' + this.$route.params.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.form.nama_eksekutor = data.data[0].nama_eksekutor
          self.form.pabrik_id = data.data[0].pabrik_id
          self.loading = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const nama_eksekutor = self.$v.form.nama_eksekutor.$model
      const pabrik_id = self.$v.form.pabrik_id.$model

      ApiService.post('/master/eksekutor/update/' + this.$route.params.id, {
        nama_eksekutor: nama_eksekutor,
        pabrik_id: pabrik_id,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil diubah')
            self.$router.push('/master/eksekutor')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
